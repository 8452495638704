    // --------------------------------------------------------------
    // ContentTitle
    // --------------------------------------------------------------
    const ContentTitle = (props) => {
        const {pretitle, title} = props;

        return (
            <div className="row">
                <div className="col-md-12 page-header">
                    { pretitle && <div className="page-pretitle">{pretitle}</div> }
                    <h2 className="page-title">{title}</h2>
                </div>
            </div>
        );

    }

export default ContentTitle;