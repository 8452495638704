import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';

import InputText from "./../../components/InputText";
import Button from "./components/button";
import ButtonText from "./components/buttonLink";

import logo from "./../../assets/img/Logo.png";
import "./Login.css";

import {
    Redirect
} from 'react-router-dom';


import { useSelector,useDispatch } from "react-redux";
import {
    selectUsuarioSeleccionado,
  //   selectUsuariosLoading,
    selectUsuariosError
  } from "../../redux/reducer/loginReducer";
import { loginUser } from "./../../redux/actions/login.action";
  
const Login = () => {
    const MESSAGE_CORREO_VALIDO = "Introduce un correo electrónico válido";
    const MESSAGE_PASSWORD_VALIDO = "El password tiene que tener al menos 6 carácteres";

    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [emailEsValido, setEmailEsValido] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordEsValido, setPasswordEsValido] = useState(false);

    const dispatch = useDispatch();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const error = useSelector(selectUsuariosError);
    const [enviar, setEnviar] = useState(false);

    useEffect( () => {
        // Validación del correo electronico
        if (email===null || email===""){  
            setEmailMessage(""); 
            setEmailEsValido(false); 
        } else {
            const atposition=email.indexOf("@");  
            const dotposition=email.lastIndexOf(".");  
            const spaceposition = email.indexOf(" ");
            if (spaceposition!==-1 || atposition<1 || dotposition<atposition+2 || dotposition+2>=email.length){  
                setEmailMessage( MESSAGE_CORREO_VALIDO );  
                setEmailEsValido(false);
            } else {
                setEmailMessage("");
                setEmailEsValido(true);
            }  
        }

        // Validación del password
        if (password===null || password===""){  
            setPasswordMessage("");  
            setPasswordEsValido(false);  
        } else {
            if(password.length < 6) {
                setPasswordMessage(MESSAGE_PASSWORD_VALIDO);
                setPasswordEsValido(false);                
            } else {
                setPasswordMessage("");
                setPasswordEsValido(true);
            }
        }

        setEnviar(false);

    }, [email, password] );
    
    useEffect(() => {
        
        if(emailEsValido && passwordEsValido) {
            dispatch(loginUser(email,password));
        }

    }, [enviar]);

    useEffect(() => {
        
        enviar && error && notify();
                
    }, [enviar,error]);
    
    function handleSubmit(event) {
        // alert("Se puede enviar: " + (emailEsValido && passwordEsValido));

        (emailEsValido && passwordEsValido) && setEnviar(true);

        
                                    
        event.preventDefault();
    }

    const notify = () => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        setEnviar(false);
    }
    return (
    <>
        { 
        usuarioSeleccionado 
        
        ?
        
        <Redirect to="/home" />
        
        :
        
        <form className="h-100" onSubmit={ () => handleSubmit}>
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-sm d-flex h-100 left">
                        <div className="contenido align-self-center ">
                            <img src={logo} alt="logo" className="mb-5"/>
                            <div>
                                <h1>Inicio de sesión</h1>
                                <p className="mb-5">Inicia sesión con los datos que te registraste</p>

                                {(
                                    
                                    // https://fkhadra.github.io/react-toastify
                                    <ToastContainer closeButton={false} />
                                    
                                    
                                )}

                                <InputText 
                                    name="email" 
                                    type="email" 
                                    label="Correo electrónico" 
                                    textHelp={MESSAGE_CORREO_VALIDO}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    errorMessage={emailMessage}
                                    esValido={emailEsValido}
                                />
                                <InputText 
                                    name="pass" 
                                    type="password" 
                                    label="Contraseña"
                                    onChange={(e) => setPassword(e.target.value)}
                                    textHelp={MESSAGE_PASSWORD_VALIDO}
                                    value={password}
                                    errorMessage={passwordMessage}
                                    esValido={passwordEsValido}
                                />

                                <Button text="Entrar" isBlock="true"  disabled={!((emailEsValido && passwordEsValido))} onClick={handleSubmit}/>
                                <div className="row justify-content-end" style={{display: "none"}}>
                                    <ButtonText text="Recordar contraseña" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm right">
                        
                    </div>
                </div>
            </div>
        </form>
        }
    </>
    )
};

export default Login;