import Login from './pages/login';
import Logout from './pages/logout';
import Home from './pages/home';
import Clientes from './pages/clientes';
import Proyectos from './pages/proyectos';
import Listados from './pages/listados';
import NotFound from './pages/notFound';

import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";


function PrivateRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

const RouteAbout = () => {
  return (
    <Route path="/about">
      About page
    </Route>
  );
}
const RouteHome = () => {
  return (
    <Route component={Home} path="/home"></Route>
  );
}
const RouteClientes = () => {
  return (
    <Route component={Clientes} path="/clientes"></Route>
  );
}
const RouteProyectos = () => {
  return (
    <Route component={Proyectos} path="/proyectos"></Route>
  );
}
const RouteListados = () => {
  return (
    <Route component={Listados} path="/listados"></Route>
  );
}


export default function App(props) {
    const usuarioSeleccionado = useSelector(state => {
      return selectUsuarioSeleccionado(state);
    });

    return ( 

      <BrowserRouter>
        <Switch>
          
          {/* ZONA PUBLICA */}
          <Route component={Login} path="/login"></Route>
          <Route component={Logout} path="/logout"></Route>
          <Route exact path="/">
            <div className="container">
              <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link>
              <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link>
            </div>
          </Route>

          {/* ZONA PRIVADA */}
          <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/about' component={RouteAbout} />
          <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/home' component={RouteHome} />
          <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/clientes' component={RouteClientes} />
          <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/proyectos' component={RouteProyectos} />
          <PrivateRoute authed={ (usuarioSeleccionado ? true : false) } path='/listados' component={RouteListados} />
          
          {/* PAGINA NO ENCONTRADA */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>

    )
  }