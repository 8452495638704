import { useSelector,useDispatch } from "react-redux";

import {
    selectClassToggleSidebar,
} from "../../redux/reducer/sidebarReducer";

import NavbarTop from "./../NavbarTop";
import Sidebar from "./../Sidebar";
import ContentTitle from "./../ContentTitle";

const TemplateDashboard = (props) => {
    const {pretitle, title} = props;

    const toggleSidebarValue = useSelector(state => {
        return selectClassToggleSidebar(state);
    });

    return (
        <>                
            {/* https://github.com/alexis-luna/bootstrap-simple-admin-template */}
            <div className="wrapper">
                {/* <!-- sidebar navigation component --> */}
                <Sidebar classToggleSidebar={toggleSidebarValue}/>

                {/* <!-- end of sidebar component --> */}
                <div id="body" className={toggleSidebarValue}>
                    {/* <!-- navbar navigation component --> */}
                    
                    <NavbarTop />
                    {/* <!-- end of navbar navigation --> */}

                    <div className="content">
                        <div className="container">

                            <ContentTitle pretitle={pretitle} title={title}/>

                            {props.children}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TemplateDashboard;