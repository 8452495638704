import { SIDEBAR_SUCCESS, SIDEBAR_CLEAR } from "./../types/sidebar.types"

const initialState = {
  classToggleSidebar: "active",
};

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_SUCCESS:
      return { ...state, loading: false, classToggleSidebar: action.payload, error: null };
    case SIDEBAR_CLEAR:
      return { ...state, loading: false, classToggleSidebar: "", error: null };
    default:
      return state;
   }
}

function selectClassToggleSidebar(state) {
  return state.sidebar.classToggleSidebar;
}


export default sidebarReducer;
export {
  selectClassToggleSidebar,
}; 