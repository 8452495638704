import { useSelector,useDispatch } from "react-redux";

import { toggleSidebar } from "./../../redux/actions/sidebar.action";

import {
    selectUsuarioSeleccionado,
  } from "../../redux/reducer/loginReducer";
  
  import {
    Link,
  } from 'react-router-dom';

const NavbarTop = () => {
    // --------------------------------------------------------------
    // handleAbrirSidebar
    //
    // Permite abrir y cerrar la sidebar
    // --------------------------------------------------------------
    const dispatch = useDispatch();
	
    const handleAbrirSidebar = () => {
        dispatch(toggleSidebar());
    }

    // --------------------------------------------------------------
    // Usuario seleccionado
    // --------------------------------------------------------------
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-white bg-white">
                <button type="button" onClick={ () => handleAbrirSidebar() } id="sidebarCollapse" className="btn btn-light"><i className="fas fa-bars"></i><span></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav ml-auto">
                        {/* <li className="nav-item dropdown">
                            <div className="nav-dropdown">
                                <a href="" className="nav-item nav-link dropdown-toggle text-secondary" data-toggle="dropdown"><i className="fas fa-link"></i> <span>Quick Links</span> <i style={{fontSize: '.8em'}} className="fas fa-caret-down"></i></a>
                                <div className="dropdown-menu dropdown-menu-right nav-link-menu">
                                    <ul className="nav-list">
                                        <li><a href="" className="dropdown-item"><i className="fas fa-list"></i> Access Logs</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a href="" className="dropdown-item"><i className="fas fa-database"></i> Back ups</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a href="" className="dropdown-item"><i className="fas fa-cloud-download-alt"></i> Updates</a></li>
                                        <div className="dropdown-divider"></div>
                                        <li><a href="" className="dropdown-item"><i className="fas fa-user-shield"></i> Roles</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li> */}
                        <li className="nav-item dropdown">
                            <div className="nav-dropdown">
                                <a href="./" className="nav-item nav-link dropdown-toggle text-secondary" data-toggle="dropdown"><i className="fas fa-user"></i> <span>{ usuarioSeleccionado !== null && usuarioSeleccionado.username }</span> <i style={{fontSize: ".8em"}} className="fas fa-caret-down"></i></a>
                                <div className="dropdown-menu dropdown-menu-right nav-link-menu">
                                    <ul className="nav-list">
                                        {/* <li><a href="" className="dropdown-item"><i className="fas fa-address-card"></i> Profile</a></li>
                                        <li><a href="" className="dropdown-item"><i className="fas fa-envelope"></i> Messages</a></li>
                                        <li><a href="" className="dropdown-item"><i className="fas fa-cog"></i> Settings</a></li>
                                        <div className="dropdown-divider"></div> */}
                                        <li>
                                        <Link className="dropdown-item" to="/logout"><i className="fas fa-sign-out-alt"></i> Salir</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
    );
}

export default NavbarTop;