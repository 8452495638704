const InputText = (props) => {
    const {type, name,label,textHelp, value,onChange, errorMessage, esValido} = props;
    return (
    <>
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <input type={type} className="form-control" id={name} name={name} aria-describedby="emailHelp" value={value} onChange={onChange}/>
            {(!esValido && textHelp && !errorMessage ? <small id="emailHelp" className="form-text text-muted">{textHelp}</small> : <></> )}
            {(!esValido && errorMessage ? <small id="emailHelp" className="form-text text-danger">{errorMessage}</small> : <></> )}
            {(esValido ? <small id="emailHelp" className="form-text text-success">Correcto</small> : <></> )}
        </div>
    </>
    )
};

export default InputText;