import {
    Link,
} from 'react-router-dom';
import React from 'react';

const HomeContent = () => {
    return (
        <>
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
                    <div className="card">
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="icon-big text-center">
                                        <i className="teal fas fa-shopping-cart"></i>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="detail">
                                        <p className="detail-subtitle">New Orders</p>
                                        <span className="number">6,267</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-calendar"></i> For this Week
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
                    <div className="card">
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="icon-big text-center">
                                        <i className="olive fas fa-money-bill-alt"></i>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="detail">
                                        <p className="detail-subtitle">Revenue</p>
                                        <span className="number">$180,900</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-calendar"></i> For this Month
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
                    <div className="card">
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="icon-big text-center">
                                        <i className="violet fas fa-eye"></i>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="detail">
                                        <p className="detail-subtitle">Page views</p>
                                        <span className="number">28,210</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-stopwatch"></i> For this Month
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
                    <div className="card">
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="icon-big text-center">
                                        <i className="orange fas fa-envelope"></i>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="detail">
                                        <p className="detail-subtitle">Support Request</p>
                                        <span className="number">75</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer">
                                <hr />
                                <div className="stats">
                                    <i className="fas fa-envelope-open-text"></i> For this week
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default HomeContent;