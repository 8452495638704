import "./Button.css";

const Button = (props) => {
    const {text, isBlock, disabled, onClick} = props;
    const classn = "btn btn-primary " + (isBlock === "true" ? "btn-block" : "");

    return (
    <>
        {
        ( disabled === true ?
            <button type="button" className={classn} disabled>{text}</button>
            :
            <button type="button" className={classn} onClick={onClick}>{text}</button>
        )
        }
    </>
    )
};

export default Button;