import "./ButtonLink.css";

const ButtonLink = (props) => {
    const {text} = props;
    
    return (
    <>
        <button type="button" className="btn btn-link">{text}</button>
    </>
    )
};

export default ButtonLink;