import { selectClassToggleSidebar } from "../reducer/sidebarReducer";
import {SIDEBAR_SUCCESS, SIDEBAR_CLEAR} from "./../types/sidebar.types"

function sidebarSuccess(payload) {
    return { type: SIDEBAR_SUCCESS, payload };
}
function sidebarClear() {
    return { type: SIDEBAR_CLEAR };
}

function toggleSidebar() {
    return async function thunk(dispatch, getState) {
        const classToggleSidebar = selectClassToggleSidebar(getState());

        if(classToggleSidebar === "") {
            dispatch(sidebarSuccess("active"));
        } else {
            dispatch(sidebarSuccess(""));
        }
    }
}

function toggleSidebarClear() {
    return async function thunk(dispatch, getState) {
        dispatch(sidebarClear());
    }
}
export {
    sidebarSuccess,
    toggleSidebar,
    toggleSidebarClear
};

