import React from 'react';
import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
// import HomeContent from "./components/HomeContent"
  
const Proyectos = () => {

    return (
    <>

        <TemplateDashboard pretitle="Vista general" title="Proyectos">
            {/* <HomeContent /> */}
        </TemplateDashboard>
        
    </>
    )
};
  
export default Proyectos;